import styled from 'styled-components';

const Column = styled.div`
  flex: ${({ flex }) => flex || '1'};
  align-self: ${({ align }) => align || 'auto'};
  justify-self: ${({ justify }) => justify || 'auto'};
  margin: 0 15px;
`;

export default Column;
