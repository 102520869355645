import styled from 'styled-components';

import theme from 'styles/theme';

export default styled.div`
  display: block;
  margin-bottom: 35px;

  a {
    color: initial;

    &:focus {
      text-decoration: none;

      h2 {
        text-decoration: underline;
      }
    }

    &:hover {
      color: initial;

      h2 {
        color: ${theme.colors.blue};
      }
    }
  }

  h2 {
    font-size: 18px;
    transition: color 0.2s;
    margin: 0.6em 0 0.5em;
  }

  .meta {
    font-size: 14px;
    opacity: 0.7;

    span {
      &:first-child,
      &:nth-child(2) {
        opacity: 0.7;
      }

      &:first-child {
        position: relative;
        margin-right: 10px;
        padding-right: 10px;

        &::after {
          content: '';
          position: absolute;
          background-color: #000000;
          height: 2px;
          width: 2px;
          right: 0;
          top: 10px;
        }
      }
    }
  }

  .category {
    font-size: 14px;
    text-transform: uppercase;
    color: ${theme.colors.blue};
  }

  p {
    font-size: 14px;
    opacity: 0.7;
  }

  &.is-vertical {
    h2 {
      font-size: 26px;
    }

    p,
    .category,
    .meta {
      font-size: 16px;
    }

    .meta {
      span:nth-child(2) {
        position: relative;
        margin-right: 10px;
        padding-right: 10px;

        &::after {
          content: '';
          position: absolute;
          background-color: #000000;
          height: 2px;
          width: 2px;
          right: 0;
          top: 10px;
        }
      }
    }
  }

  &.is-regular {
    margin: 15px;
    max-width: calc(50% - 30px);
    min-width: calc(50% - 30px);

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  &.hide-excerpt {
    p {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }
  }
`;
