import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Row from 'components/Row';
import Column from 'components/Column';
import ArchiveItem from 'components/ArchiveItem';

const IndexPage = ({ data, location }) => {
  const posts = data.posts.edges;
  const authors = data.authors.nodes;

  return (
    <Layout location={location}>
      <Helmet title="Facticular Home" />
      <Container>
        <Row
          align="flex-start"
          mobileColumn
          style={{ marginBottom: 25, paddingTop: 50 }}>
          <Column flex="4">
            <ArchiveItem post={posts[0].node} authors={authors} isVertical />
          </Column>
          <Column flex="3">
            {posts.slice(1, 4).map(({ node }) => (
              <ArchiveItem
                key={node.fields.slug}
                post={node}
                authors={authors}
                hideExcerpt
              />
            ))}
          </Column>
        </Row>
        <Row
          justify="flex-start"
          align="flex-start"
          mobileColumn
          style={{ paddingBottom: 50 }}>
          {posts.slice(5).map(({ node }) => (
            <ArchiveItem
              key={node.fields.slug}
              post={node}
              authors={authors}
              isRegular
            />
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/facts//" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featured_image
            author
            date(formatString: "MMMM DD, YYYY")
            categories
          }
        }
      }
    }

    authors: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/authors//" } }
    ) {
      nodes {
        frontmatter {
          username
          name
          bio
          avatar
        }
      }
    }
  }
`;
