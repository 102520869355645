/* eslint-disable arrow-body-style */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Row from 'components/Row';
import Column from 'components/Column';
import Image from 'components/Image';

import Item from './ArchiveItem.styled';

const ArchiveItem = ({
  post,
  // authors,
  hideExcerpt,
  isVertical,
  isRegular,
  style,
}) => {
  /* const getAuthor = useCallback(
    (u) =>
      authors.find(({ frontmatter }) => frontmatter.username === u).frontmatter,
    [authors],
  ); */

  return (
    <Item
      className={`${isVertical ? 'is-vertical' : ''}${
        isRegular ? ' is-regular' : ''
      }${hideExcerpt ? ' hide-excerpt' : ''}`}
      style={style}>
      <Link
        to={`/${post.frontmatter.categories}/${post.fields.slug}`}
        key={post.fields.slug}>
        {isVertical ? (
          <>
            <Image
              src={post.frontmatter.featured_image}
              alt={post.frontmatter.title}
              sizes="(max-width: 800px) 100vw, 800px"
            />
            <h2>{post.frontmatter.title}</h2>
            <div className="meta">
              {/* <span>{getAuthor(post.frontmatter.author).name}</span> */}
              <span>{post.frontmatter.date}</span>
              <span className="category">{post.frontmatter.categories}</span>
            </div>
            <p>
              {`${post.frontmatter.description.substring(0, 140)}${
                post.frontmatter.description.length > 140 ? '...' : ''
              }`}
            </p>
          </>
        ) : (
          <>
            <Row align="flex-start" style={{ marginBottom: 25 }}>
              <Column flex="3">
                <Image
                  src={post.frontmatter.featured_image}
                  alt={post.frontmatter.title}
                  sizes="(max-width: 400px) 100vw, 400px"
                />
              </Column>
              <Column flex="4">
                <span className="category">{post.frontmatter.categories}</span>
                <h2>{post.frontmatter.title}</h2>
                <p>
                  {`${post.frontmatter.description.substring(0, 90)}${
                    post.frontmatter.description.length > 90 ? '...' : ''
                  }`}
                </p>
                <div className="meta">
                  {/* <span>{getAuthor(post.frontmatter.author).name}</span> */}
                  <span>{post.frontmatter.date}</span>
                </div>
              </Column>
            </Row>
          </>
        )}
      </Link>
    </Item>
  );
};

ArchiveItem.propTypes = {
  // authors: PropTypes.array.isRequired,
  post: PropTypes.object.isRequired,
  hideExcerpt: PropTypes.bool,
  isVertical: PropTypes.bool,
  isRegular: PropTypes.bool,
  style: PropTypes.object,
};

export default ArchiveItem;
